<template>
    <div>
        <b-card class="rounded-lg">
            <b-card-text class="d-flex">
                {{ $t(locale.title) }}
            </b-card-text>

            <b-card-text>
                <Loading v-if="loadingState" />
                <b-card v-if="displayTable" no-body>
                    <div class="m-2">
                        <b-row>
                            <b-col
                                cols="12"
                                md="6"
                                class="align-items-center justify-content-start px-0 mb-md-0"
                            >
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                        class="d-flex pb-1 align-items-center"
                                    >
                                        <date-picker
                                            valueType="format"
                                            class="w-100"
                                            v-model="start"
                                            prefix-class="xmx"
                                            @input="startDate"
                                            :placeholder="$t(locale.startDate)"
                                        />
                                    </b-col>

                                    <b-col cols="12" class="pb-1" md="6">
                                        <date-picker
                                            valueType="format"
                                            prefix-class="xmx"
                                            class="w-100"
                                            v-model="end"
                                            @input="endDate"
                                            :placeholder="$t(locale.endDate)"
                                        />
                                    </b-col>
                                </b-row>
                            </b-col>

                            <b-col
                                cols="12"
                                md="6"
                                class="align-items-center justify-content-end mb-1 px-0 mb-md-0"
                            >
                                <b-row class="pl-md-1">
                                    <b-col cols="12" md="6" class="pb-1">
                                        <v-select
                                            v-model="filterBy"
                                            :placeholder="$t(locale.filterBy)"
                                            :options="$t(locale.filter)"
                                            class="resource-selector d-inline-block"
                                        />
                                    </b-col>

                                    <b-col cols="12" class="mb-1" md="6">
                                        <b-form-input
                                            v-model="searchQuery"
                                            :placeholder="$t(locale.search)"
                                        />
                                    </b-col>
                                </b-row>
                            </b-col>

                            <b-table
                                ref="table"
                                responsive
                                :items="items"
                                :fields="tableColumns"
                                selectable
                                primary-key="id"
                                @row-clicked="onRowSelected"
                                show-empty
                                :sort-desc.sync="sortDesc"
                                :sort-by.sync="sortBy"
                                :empty-text="$t(locale.tableEmpty)"
                                class="position-relative mt-2"
                            >
                                <template #head(checkbox) style="width: 20px">
                                    <div class="d-flex" style="margin-top: 1px">
                                        <b-form-checkbox
                                            v-model="selected"
                                            value="A"
                                            class="custom-control-primary my-auto"
                                        >
                                        </b-form-checkbox>
                                    </div>
                                </template>
                                <template #head(date)="data">
                                    <div class="d-flex">
                                        <span
                                            class="my-auto"
                                            style="margin-right: 5px"
                                            v-text="$t(data.field.label)"
                                        />

                                        <span
                                            class="d-flex flex-column position-relative"
                                        >
                                            <feather-icon
                                                size="10"
                                                @click="
                                                    sortingChanged(data, true)
                                                "
                                                class="cursor-pointer"
                                                icon="ChevronUpIcon"
                                                style="pointer: cursor"
                                                role="button"
                                            />

                                            <feather-icon
                                                @click="
                                                    sortingChanged(data, false)
                                                "
                                                size="10"
                                                class="cursor-pointer"
                                                icon="ChevronDownIcon"
                                                role="button"
                                            />
                                        </span>
                                    </div>
                                </template>
                                <template #head(resource)="data">
                                    <div class="d-flex">
                                        <span
                                            class="my-auto"
                                            style="margin-right: 5px"
                                            v-text="$t(data.field.label)"
                                        />

                                        <span
                                            class="d-flex flex-column position-relative"
                                        >
                                            <feather-icon
                                                size="10"
                                                @click="
                                                    sortingChanged(data, true)
                                                "
                                                class="cursor-pointer"
                                                icon="ChevronUpIcon"
                                                style="pointer: cursor"
                                                role="button"
                                            />

                                            <feather-icon
                                                @click="
                                                    sortingChanged(data, false)
                                                "
                                                size="10"
                                                class="cursor-pointer"
                                                icon="ChevronDownIcon"
                                                role="button"
                                            />
                                        </span>
                                    </div>
                                </template>
                                <template #head(activity)="data">
                                    <div class="d-flex">
                                        <span
                                            class="my-auto"
                                            style="margin-right: 5px"
                                            v-text="$t(data.field.label)"
                                        />

                                        <span
                                            class="d-flex flex-column position-relative"
                                        >
                                            <feather-icon
                                                size="10"
                                                @click="
                                                    sortingChanged(data, true)
                                                "
                                                class="cursor-pointer"
                                                icon="ChevronUpIcon"
                                                style="pointer: cursor"
                                                role="button"
                                            />

                                            <feather-icon
                                                @click="
                                                    sortingChanged(data, false)
                                                "
                                                size="10"
                                                class="cursor-pointer"
                                                icon="ChevronDownIcon"
                                                role="button"
                                            />
                                        </span>
                                    </div>
                                </template>
                                <template #head(type)="data">
                                    <div class="d-flex">
                                        <span
                                            class="my-auto"
                                            style="margin-right: 5px"
                                            v-text="$t(data.field.label)"
                                        />

                                        <span
                                            class="d-flex flex-column position-relative"
                                        >
                                            <feather-icon
                                                size="10"
                                                @click="
                                                    sortingChanged(data, true)
                                                "
                                                class="cursor-pointer"
                                                icon="ChevronUpIcon"
                                                style="pointer: cursor"
                                                role="button"
                                            />

                                            <feather-icon
                                                @click="
                                                    sortingChanged(data, false)
                                                "
                                                size="10"
                                                class="cursor-pointer"
                                                icon="ChevronDownIcon"
                                                role="button"
                                            />
                                        </span>
                                    </div>
                                </template>
                                <template #head(subject)="data">
                                    <div class="d-flex">
                                        <span
                                            class="my-auto"
                                            style="margin-right: 5px"
                                            v-text="$t(data.field.label)"
                                        />

                                        <span
                                            class="d-flex flex-column position-relative"
                                        >
                                            <feather-icon
                                                size="10"
                                                @click="
                                                    sortingChanged(data, true)
                                                "
                                                class="cursor-pointer"
                                                icon="ChevronUpIcon"
                                                style="pointer: cursor"
                                                role="button"
                                            />

                                            <feather-icon
                                                @click="
                                                    sortingChanged(data, false)
                                                "
                                                size="10"
                                                class="cursor-pointer"
                                                icon="ChevronDownIcon"
                                                role="button"
                                            />
                                        </span>
                                    </div>
                                </template>

                                <template #cell(checkbox)="data">
                                    <b-form-checkbox v-model="data.value">
                                    </b-form-checkbox>
                                </template>

                                <!-- Column: date -->
                                <template #cell(date)="data">
                                    <div class="d-flex">
                                        <p class="font-weight-bold mb-0">
                                            {{ data.value }}
                                        </p>
                                    </div>
                                </template>

                                <template #cell(type)="data">
                                    <span
                                        class="text-nowrap text-uppercase checking"
                                        :class="typeColor(data.value[event])"
                                        v-text="data.value[event]"
                                    />
                                </template>

                                <template #cell(subject)="data">
                                    <div class="d-flex">
                                        <p class="font-weight-bold mb-0">
                                            {{ data.value[subject] }}
                                        </p>
                                    </div>
                                </template>

                                <template #cell(resource)="data">
                                    <div class="d-flex">
                                        <p class="font-weight-bold mb-0">
                                            {{ data.value[resource] }}
                                        </p>
                                    </div>
                                </template>

                                <template #cell(activity)="data">
                                    <div class="d-flex">
                                        <p class="font-weight-bold mb-0">
                                            {{ data.value[activity] }}
                                        </p>
                                    </div>
                                </template>
                            </b-table>
                        </b-row>
                    </div>

                    <!-- Table -->

                    <div class="mx-2 mb-2">
                        <b-row align-h="end">
                            <!-- Pagination -->
                            <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                            >
                                <b-pagination
                                    v-model="pagination.currentPage"
                                    :total-rows="pagination.totalEventLog"
                                    :per-page="pagination.perPage"
                                    first-number
                                    last-number
                                    class="mb-0 mt-1 mt-sm-0"
                                    prev-class="prev-item"
                                    next-class="next-item"
                                >
                                    <template #prev-text>
                                        <feather-icon
                                            icon="ChevronLeftIcon"
                                            size="18"
                                        />
                                    </template>
                                    <template #next-text>
                                        <feather-icon
                                            icon="ChevronRightIcon"
                                            size="18"
                                        />
                                    </template>
                                </b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                </b-card>
            </b-card-text>
        </b-card>

        <b-modal
            v-model="openModal"
            ok-variant="primary"
            ok-only
            hide-footer
            :ok-title="$t(locale.modalConfirm)"
            :cancel-title="$t(locale.modalCancel)"
            cancel-variant="outline-secondary"
            modal-class="modal-primary"
            centered
            @close="clearSelected"
            @hide="clearSelected"
            clickToClose
            :title="$t(locale.modalTitle)"
        >
            <b-card-text
                class="d-flex flex-column pb-2 justify-content-center"
                style="gap: 1rem"
            >
                <div class="">
                    <div class="">
                        <p
                            class="font-weight-bold h6"
                            v-text="$t(locale.typeTitle)"
                        />
                        <p
                            class="mb-0 pb-0 pl-1"
                            :class="typeColor(currentEvent[event])"
                            v-text="currentEvent[event]"
                        />
                    </div>

                    <div class="mt-2">
                        <p
                            class="font-weight-bold h6"
                            v-text="$t(locale.date)"
                        />
                        <p class="mb-0 pb-0 pl-1" v-text="currentEvent.date" />
                    </div>

                    <div class="mt-2">
                        <p
                            class="font-weight-bold h6"
                            v-text="$t(locale.subject)"
                        />
                        <p
                            class="mb-0 pb-0 pl-1"
                            v-text="currentEvent[subject]"
                        />
                    </div>

                    <div class="mt-2">
                        <p
                            class="font-weight-bold h6"
                            v-text="$t(locale.msg)"
                        />
                        <p
                            class="mb-0 pb-0 pl-1"
                            v-text="currentEvent[message]"
                        />
                    </div>
                </div>
            </b-card-text>
        </b-modal>
    </div>
</template>

<script>
import {dateMixins} from '@/mixins/dateMixins';
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import vSelect from 'vue-select';
import useAppConfig from '@core/app-config/useAppConfig';
import DatePicker from 'vue2-datepicker';

export default {
    mixins: [dateMixins],
    components: {
        vSelect,
        DatePicker,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            selected: false,
            searchQuery: '',
            filterBy: '',
            sortDesc: null,
            items: [],
            openModal: false,
            loadingState: true,
            displayTable: false,
            config: useAppConfig(),
            sortBy: '',
            start: '',
            end: '',
            currentEvent: '',
            tableColumns: [
                {
                    key: 'checkbox',
                    tdClass: 'bTableThStyle',
                    thStyle: {
                        padding: '0.72rem 10px',
                        width: '20px',
                    },
                },
                {
                    key: 'date',
                    label: 'EventLogCard.dateHead',
                    tdClass: 'bTableTdDateStyle',
                    thStyle: {padding: '0.72rem 0', width: '150px'},
                },
                {
                    key: 'resource',
                    tdClass: 'bTableTdDateStyle',
                    label: 'EventLogCard.resourceHead',
                    thStyle: {padding: '0.72rem 0', width: '150px'},
                },
                {
                    key: 'activity',
                    tdClass: 'bTableTdDateStyle',
                    label: 'EventLogCard.activityHead',
                    thStyle: {padding: '0.72rem 0', width: '190px'},
                },
                {
                    key: 'type',
                    label: 'EventLogCard.typeHead',
                    tdClass: 'bTableTdDateStyle',
                    thStyle: {padding: '0.72rem 0', width: '150px'},
                },
                {
                    key: 'subject',
                    label: 'EventLogCard.subjectHead',
                    tdClass: 'bTableTdDateStyle',
                    thStyle: {padding: '0.72rem 0', width: '290px'},
                },
            ],
            pagination: {
                currentPage: 1,
                totalEventLog: 0,
                perPage: 20,
            },
            locale: {
                title: 'EventLogCard.title',
                typeTitle: 'EventLogCard.typeHead',
                success: 'EventLogCard.success',
                date: 'Notification.date',
                subject: 'EventLogCard.subjectHead',
                notifPlaceholder: 'Notification.subjectPlaceholder',
                msgPlaceholder: 'Notification.messagePlaceholder',
                msg: 'Notification.message',
                modalTitle: 'EventLogCard.modalTitle',
                modalConfirm: 'SubscriptionCard.ModalConfirm',
                modalCancel: 'SubscriptionCard.ModalCancel',
                tableEmpty: 'EventLogCard.noMatchRecordFound',
                search: 'EventLogCard.searchPlaceHolder',
                filter: 'EventLogCard.filter',
                filterBy: 'EventLogCard.filterBy',
                startDate: 'EventLogCard.startDate',
                endDate: 'EventLogCard.endDate',
            },
            paramsQuery: {},
        };
    },

    watch: {
        filterBy(value) {
            if (value) {
                if (value.params == 'eng_event') {
                    this.paramsQuery.eng_event = value.value;
                    delete this.paramsQuery.swe_event;
                    this.getEventByParams();
                } else {
                    this.paramsQuery.swe_event = value.value;
                    delete this.paramsQuery.eng_event;
                    this.getEventByParams();
                }
            } else {
                delete this.paramsQuery.swe_event;
                delete this.paramsQuery.eng_event;
                this.getEventByParams();
            }
        },
        searchQuery(value) {
            if (value) {
                this.paramsQuery.search = value;
                this.getEventByParams();
            } else {
                delete this.paramsQuery.search;
                this.getEventByParams();
            }
        },
        'pagination.currentPage': {
            handler(page) {
                this.$useJwt.eventLogList({params: {page: page}}).then(res => {
                    const {results} = res.data;

                    const {count} = res.data;

                    this.pagination.totalEventLog = count;

                    this.renderEvent(results);
                });
            },
        },
        getLang(value) {
            if (this.filterBy) this.changeFilterStyle();
        },
    },

    computed: {
        resource() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_resource' : 'eng_resource';
            } else
                return this.getLang == 'sv' ? 'swe_resource' : 'eng_resource';
        },
        activity() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_activity' : 'eng_activity';
            } else
                return this.getLang == 'sv' ? 'swe_activity' : 'eng_activity';
        },
        event() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_event' : 'eng_event';
            } else return this.getLang == 'sv' ? 'swe_event' : 'eng_event';
        },
        message() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_message' : 'eng_message';
            } else return this.getLang == 'sv' ? 'swe_message' : 'eng_message';
        },
        subject() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_subject' : 'eng_subject';
            } else return this.getLang == 'sv' ? 'swe_subject' : 'eng_subject';
        },

        resultMsg() {
            if (this.getLang == '') {
                return this.swedishLang ? 'message' : 'eng_message';
            } else return this.getLang == 'sv' ? 'message' : 'eng_message';
        },

        getLang() {
            return this.config.lang.value;
        },
    },
    methods: {
        changeFilterStyle() {
            this.filterBy = this.$t('EventLogCard.filter').find(
                element => element.find == this.filterBy.find
            );
        },
        startDate(date) {
            if (date) {
                this.paramsQuery.start_date = date;
                this.getEventByParams();
            } else {
                delete this.paramsQuery.start_date;
                this.getEventByParams();
            }
        },

        endDate(date) {
            if (date) {
                this.paramsQuery.end_date = date;
                this.getEventByParams();
            } else {
                delete this.paramsQuery.end_date;
                this.getEventByParams();
            }
        },

        getEventByParams() {
            this.$useJwt.eventLogList({params: this.paramsQuery}).then(res => {
                const {results} = res.data;

                const {count} = res.data;

                this.pagination.totalEventLog = count;

                this.renderEvent(results);
            });
        },
        typeColor(type) {
            switch (type) {
                case 'SUCCESS':
                    return 'text-success';
                case 'LYCKAD':
                    return 'text-success';
                case 'INFO':
                    return 'text-secondaryColor';
                case 'MISSLYCKAD':
                    return 'text-danger';
                case 'ERROR':
                    return 'text-danger';
            }
        },
        onRowSelected(value) {
            this.currentEvent = value;
            this.openModal = true;
        },
        clearSelected() {
            this.$refs.table.clearSelected();
        },

        renderEvent(value) {
            this.loadingState = true;
            this.displayTable = false;
            let dataArray = [];

            value.forEach(element => {
                dataArray.push({
                    ...element,
                    uuid: element.uuid,
                    checkbox: false,
                    date: this.getDateStringFormat(element.created_at),
                    type: {
                        swe_event: element.swe_event,
                        eng_event: element.eng_event,
                    },
                    subject: {
                        swe_subject: element.swe_subject,
                        eng_subject: element.eng_subject,
                    },
                    resource: {
                        swe_resource: element.swe_resource,
                        eng_resource: element.eng_resource,
                    },
                    activity: {
                        swe_activity: element.swe_activity,
                        eng_activity: element.eng_activity,
                    },
                });
            });

            this.items = dataArray;

            this.loadingState = false;
            this.displayTable = true;
        },
        getEventLogList() {
            this.$useJwt.eventLogList({params: {page: 1}}).then(res => {
                const {results} = res.data;

                const {count} = res.data;

                this.pagination.totalEventLog = count;

                this.renderEvent(results);
            });
        },

        sortingChanged(sortBy, desc) {
            this.sortBy = sortBy.column;
            this.sortDesc = desc;
        },
    },

    mounted() {
        this.getEventLogList();
    },
};
</script>

<style scoped lang="scss">
.resource-selector {
    width: 100%;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/datepicker.scss';
</style>
