export const dateMixins = {
    data() {
        return {
            month: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ],
        };
    },
    methods: {
        formatDate(value) {
            let date = new Date(value);
            let getDate =
                date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
            let month =
                date.getMonth() < 10
                    ? `0${date.getMonth() + 1}`
                    : date.getMonth() + 1;

            return `${date.getFullYear()}-${month}-${getDate}`;
        },
        getDateArticle(value) {
            let date = new Date(value);
            let getDate =
                date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
            let month =
                date.getMonth() < 10
                    ? `0${date.getMonth() + 1}`
                    : date.getMonth() + 1;

            return `${getDate}/${month}/${date.getFullYear()}`;
        },

        getDateStringFormat(value) {
            let date = new Date(value);

            return `${date.getDate()} ${this.$t(
                `Month.${[this.month[date.getMonth()]]}`
            )} ${date.getFullYear()}`;
        },
    },
};
